<template>
  <tt-index v-if="baseInfo && categoryItems"></tt-index>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "indexPage",
  computed: {
    ...mapState({
      baseInfo: (state) => state.baseInfo,
      categoryItems: (state) => state.categoryItems,
    }),
  },
};
</script>
